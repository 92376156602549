/**
 *Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 *You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 *The code base belongs to Data & AI Services - ADAI Practice
 *Please refer to the agreement/license for specific  governing permissions and
 *limitations under the License.
 *Code Author and Ownership:
 *
 *Prabhu Parthasarathy ,
 *Sr. Product Architect,
 *Email: prabhu.parthasarathy@kyndryl.com
 */
import { APPLICATION_ERROR, DIALOG_TOGGLE } from '../actionCreators/actionTypes'

interface IToggleExpandAction {
  type: string
  payload: {
    message: string
    field: string
  }
}
export interface IDisplayState {
  error: string
  success: string
  dialogIsOpen: boolean
  editWarningField?: string
  isLoading: boolean
}

const initialState: IDisplayState = {
  error: '',
  success: '',
  dialogIsOpen: false,
  editWarningField: '',
  isLoading: true
}

export default (state = initialState, action: IToggleExpandAction) => {
  if (action.type.toLowerCase().includes('success')) {
    return { ...state, isLoading: false }
  } else if (action.type.toLowerCase().includes('fetch')) {
    return { ...state, isLoading: true }
  }
  switch (action.type) {
    case APPLICATION_ERROR:
      return { ...state, error: action.payload.message, success: '' }
    case DIALOG_TOGGLE:
      return { ...state, dialogIsOpen: !state.dialogIsOpen, editWarningField: action.payload.field }
    default:
      return state
  }
}
