/**
 *Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 *You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 *The code base belongs to Data & AI Services - ADAI Practice
 *Please refer to the agreement/license for specific  governing permissions and
 *limitations under the License.
 *Code Author and Ownership:
 *
 *Prabhu Parthasarathy ,
 *Sr. Product Architect,
 *Email: prabhu.parthasarathy@kyndryl.com
 */
import * as React from 'react'
import * as ReactDOM from 'react-dom'
require('../node_modules/react-vis/dist/style.css')
import App from './components/App'

// fonts
import './fonts/SourceCodePro-Bold.ttf'
import './fonts/SourceCodePro-Regular.ttf'
import './index.css'

ReactDOM.render(<App />, document.getElementById('root'))
