/**
 *Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 *You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 *The code base belongs to Data & AI Services - ADAI Practice
 *Please refer to the agreement/license for specific  governing permissions and
 *limitations under the License.
 *Code Author and Ownership:
 *
 *Prabhu Parthasarathy ,
 *Sr. Product Architect,
 *Email: prabhu.parthasarathy@kyndryl.com
 */
import { HEADER_HEIGHT } from '../../../helpers/theme'
import Box from '@material-ui/core/Box/Box'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import React, { ReactElement } from 'react'

interface MqScreenLoadProps {
  children?: ReactElement
  loading: boolean
  noHeader?: boolean
}

export const MqScreenLoad: React.FC<MqScreenLoadProps> = ({ loading, children, noHeader }) => {
  return loading || !children ? (
    <Box
      height={noHeader ? 'calc(100vh)' : `calc(100vh - ${HEADER_HEIGHT}px)`}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <CircularProgress color='primary' />
    </Box>
  ) : (
    children
  )
}
