exports = module.exports = require("../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./fonts/SourceCodePro-Regular.ttf"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("./fonts/SourceCodePro-Bold.ttf"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("./fonts/Karla/Karla-Regular.ttf"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("./fonts/Karla/Karla-Bold.ttf"));

// Module
exports.push([module.id, "/*\r\n *Copyright (C) 2022 Kyndryl Inc All Rights Reserved\r\n *You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.\r\n *The code base belongs to Data & AI Services - ADAI Practice\r\n *Please refer to the agreement/license for specific  governing permissions and\r\n *limitations under the License.\r\n *Code Author and Ownership:\r\n *\r\n *Prabhu Parthasarathy ,\r\n *Sr. Product Architect,\r\n *Email: prabhu.parthasarathy@kyndryl.com\r\n */\r\nbody {\r\n  margin: 0;\r\n  padding: 0;\r\n  background-color: white !important;\r\n  font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n  color: black;\r\n}\r\n\r\ncode {\r\n  font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif,\r\n    monospace;\r\n}\r\n\r\n@font-face {\r\n  font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;\r\n  src: local('Source Code Pro'), url(" + ___CSS_LOADER_URL___0___ + ") format('truetype'), local('Source Code Pro'), url(" + ___CSS_LOADER_URL___1___ + ") format('truetype');\r\n}\r\n\r\n@font-face {\r\n  font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;\r\n  src: local('Karla'), url(" + ___CSS_LOADER_URL___2___ + ") format('truetype'), local('Karla'), url(" + ___CSS_LOADER_URL___3___ + ") format('truetype');\r\n}\r\n", ""]);

