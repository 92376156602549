/**
 *Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 *You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 *The code base belongs to Data & AI Services - ADAI Practice
 *Please refer to the agreement/license for specific  governing permissions and
 *limitations under the License.
 *Code Author and Ownership:
 *
 *Prabhu Parthasarathy ,
 *Sr. Product Architect,
 *Email: prabhu.parthasarathy@kyndryl.com
 */
//  from webpack
declare const __NODE_ENV__: string
declare const __DEVELOPMENT__: boolean

declare const __API_URL__: string

declare const __FEEDBACK_FORM_URL__: string
declare const __API_DOCS_URL__: string

export const API_URL = __API_URL__
export const FEEDBACK_FORM_URL = __FEEDBACK_FORM_URL__
export const API_DOCS_URL = __API_DOCS_URL__
