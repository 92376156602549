/**
 *Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 *You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 *The code base belongs to Data & AI Services - ADAI Practice
 *Please refer to the agreement/license for specific  governing permissions and
 *limitations under the License.
 *Code Author and Ownership:
 *
 *Prabhu Parthasarathy ,
 *Sr. Product Architect,
 *Email: prabhu.parthasarathy@kyndryl.com
 */
import { THEME_EXTRA, theme } from '../../../helpers/theme'
import { alpha } from '@material-ui/core/styles'
import { ocean } from 'react-syntax-highlighter/dist/cjs/styles/hljs'
import React from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'

interface OwnProps {
  code: object
}

const MqJson: React.FC<OwnProps> = ({ code }) => {
  return (
    <SyntaxHighlighter
      language='json'
      style={ocean}
      customStyle={{
        backgroundColor: theme.palette.common.white,
        borderLeft: `2px dashed ${THEME_EXTRA.typography.subdued}`
      }}
    >
      {JSON.stringify(code, null, '  ')}
    </SyntaxHighlighter>
  )
}

export default MqJson
