/**
 *Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 *You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 *The code base belongs to Data & AI Services - ADAI Practice
 *Please refer to the agreement/license for specific  governing permissions and
 *limitations under the License.
 *Code Author and Ownership:
 *
 *Prabhu Parthasarathy ,
 *Sr. Product Architect,
 *Email: prabhu.parthasarathy@kyndryl.com
 */
export function groupBy<T, K extends keyof T>(list: T[], key: K) {
  const map = new Map<T[K], T[]>()
  list.forEach(item => {
    const itemKey = item[key]
    if (!map.has(itemKey)) {
      map.set(itemKey, list.filter(i => i[key] === item[key]))
    }
  })
  return map
}
