/**
 *Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 *You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 *The code base belongs to Data & AI Services - ADAI Practice
 *Please refer to the agreement/license for specific  governing permissions and
 *limitations under the License.
 *Code Author and Ownership:
 *
 *Prabhu Parthasarathy ,
 *Sr. Product Architect,
 *Email: prabhu.parthasarathy@kyndryl.com
 */
import { API_URL } from '../../globals'
import { JobOrDataset } from '../../components/lineage/types'
import { LineageGraph } from '../../types/api'
import { generateNodeId } from '../../helpers/nodes'
import { genericFetchWrapper } from './index'

export const getLineage = async (nodeType: JobOrDataset, namespace: string, name: string) => {
  const url = `${API_URL}/lineage/?nodeId=${generateNodeId(nodeType, namespace, name)}`
  return genericFetchWrapper<LineageGraph>(url, { method: 'GET' }, 'fetchLineage')
}
