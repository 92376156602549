/**
 *Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 *You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 *The code base belongs to Data & AI Services - ADAI Practice
 *Please refer to the agreement/license for specific  governing permissions and
 *limitations under the License.
 *Code Author and Ownership:
 *
 *Prabhu Parthasarathy ,
 *Sr. Product Architect,
 *Email: prabhu.parthasarathy@kyndryl.com
 */
import { Dataset, Datasets } from '../../types/api'
import {
  FETCH_DATASETS,
  FETCH_DATASETS_SUCCESS,
  RESET_DATASETS
} from '../actionCreators/actionTypes'
import { fetchDatasetsSuccess } from '../actionCreators'

export type IDatasetsState = { isLoading: boolean; result: Datasets; init: boolean, totalCounts: number }

export const initialState: IDatasetsState = { isLoading: false, init: false, result: {totalCount: 0, datasets: [] }, totalCounts: 0 }

type IDatasetsAction = ReturnType<typeof fetchDatasetsSuccess>

export default (state: IDatasetsState = initialState, action: IDatasetsAction): IDatasetsState => {
  const { type, payload } = action

  switch (type) {
    case FETCH_DATASETS:
      return { ...state, isLoading: true }
    case FETCH_DATASETS_SUCCESS:
      return { ...state, isLoading: false, init: true, result: payload.datasets }
    case RESET_DATASETS:
      return initialState
    default:
      return state
  }
}
