/**
 *Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 *You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 *The code base belongs to Data & AI Services - ADAI Practice
 *Please refer to the agreement/license for specific  governing permissions and
 *limitations under the License.
 *Code Author and Ownership:
 *
 *Prabhu Parthasarathy ,
 *Sr. Product Architect,
 *Email: prabhu.parthasarathy@kyndryl.com
 */
import { API_URL } from '../../globals'
import { Search } from '../../types/api'
import { genericFetchWrapper } from './index'

export const getSearch = async (q: string, filter = 'ALL', sort = 'NAME', limit = 100) => {
  let url = `${API_URL}/search/?q=${q}&sort=${sort}&limit=${limit}`
  if (filter === 'JOB' || filter === 'DATASET') {
    url += `&filter=${filter}`
  }
  return genericFetchWrapper<Search>(url, { method: 'GET' }, 'fetchSearch')
}
