/**
 *Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 *You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 *The code base belongs to Data & AI Services - ADAI Practice
 *Please refer to the agreement/license for specific  governing permissions and
 *limitations under the License.
 *Code Author and Ownership:
 *
 *Prabhu Parthasarathy ,
 *Sr. Product Architect,
 *Email: prabhu.parthasarathy@kyndryl.com
 */
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { Field } from '../../types/api'
import MqEmpty from '../core/empty/MqEmpty'
import MqJson from '../core/code/MqJson'
import MqText from '../core/text/MqText'
import React, { FunctionComponent } from 'react'

const DATASET_COLUMNS = ['Field', 'Type', 'Description']

interface DatasetInfoProps {
  datasetFields: Field[]
  facets?: object
}

const DatasetInfo: FunctionComponent<DatasetInfoProps> = props => {
  const { datasetFields, facets } = props

  if (datasetFields.length === 0) {
    return <MqEmpty title={'No Fields'} body={'Try adding dataset fields.'} />
  }

  return (
    <Box>
      <Table size='small' style={{boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.2)"}}>
      <TableHead>
        <TableRow>
          {DATASET_COLUMNS.map(column => {
            return (
              <TableCell key={column} align='left' style={{color: "black",border: "none"}}>
                <MqText subheading inline>
                  {column}
                </MqText>
              </TableCell>
            )
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {datasetFields.map((field,index) => {
          return ( 
            <TableRow key={field.name} style ={ index % 2? { background : "white" }:{ background : "#F5F5F5" }}>
              <TableCell align='left' style={{color: "black",border: "none",fontSize: "12px"}}>{field.name}</TableCell>
              <TableCell align='left' style={{color: "black",border: "none",fontSize: "12px"}}>{field.type}</TableCell>
              <TableCell align='left' style={{color: "black",border: "none",fontSize: "12px"}}>{field.description || 'no description'}</TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
      {facets && (
      <Box mt={2}>
        <Box mb={1}>
          <MqText subheading>Facets</MqText>
        </Box>
        <MqJson code={facets} /> 
      </Box>
    )}
      
    </Box>
  )
}

export default DatasetInfo
