/**
 *Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 *You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 *The code base belongs to Data & AI Services - ADAI Practice
 *Please refer to the agreement/license for specific  governing permissions and
 *limitations under the License.
 *Code Author and Ownership:
 *
 *Prabhu Parthasarathy ,
 *Sr. Product Architect,
 *Email: prabhu.parthasarathy@kyndryl.com
 */
import { Node as GraphNode } from 'dagre'
import { MqNode } from '../../types'
import { theme } from '../../../../helpers/theme'
import React from 'react'

type NodeTextProps = {
  node: GraphNode<MqNode>
}

const TEXT_BOTTOM_SPACING = theme.spacing(3)
const MAX_CHARACTERS = 20
const LEADING_AND_TRAILING_CHARACTERS = 10

export function NodeText({ node }: NodeTextProps) {
  return (
    <text
      x={node.x}
      y={node.y + TEXT_BOTTOM_SPACING}
      fontSize='8'
      textAnchor='middle'
    >
      {node.data.name.length > MAX_CHARACTERS
        ? `${node.data.name.substring(
            0,
            LEADING_AND_TRAILING_CHARACTERS
          )}…${node.data.name.substring(node.data.name.length - LEADING_AND_TRAILING_CHARACTERS)}`
        : node.data.name}
    </text>
  )
}
