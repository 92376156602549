/**
 *Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 *You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 *The code base belongs to Data & AI Services - ADAI Practice
 *Please refer to the agreement/license for specific  governing permissions and
 *limitations under the License.
 *Code Author and Ownership:
 *
 *Prabhu Parthasarathy ,
 *Sr. Product Architect,
 *Email: prabhu.parthasarathy@kyndryl.com
 */
export const APPLICATION_ERROR = 'APPLICATION_ERROR'
export const DIALOG_TOGGLE = 'DIALOG_TOGGLE'

// runs
export const FETCH_RUNS = 'FETCH_RUNS'
export const FETCH_RUNS_SUCCESS = 'FETCH_RUNS_SUCCESS'
export const RESET_RUNS = 'RESET_RUNS'

export const FETCH_NAMESPACES_SUCCESS = 'FETCH_NAMESPACES_SUCCESS'
export const SELECT_NAMESPACE = 'SELECT_NAMESPACE'

export const SET_SELECTED_NODE = 'SET_SELECTED_NODE'

export const SET_BOTTOM_BAR_HEIGHT = 'SET_BOTTOM_BAR_HEIGHT'

// jobs
export const FETCH_JOBS = 'FETCH_JOBS'
export const FETCH_JOBS_SUCCESS = 'FETCH_JOBS_SUCCESS'
export const RESET_JOBS = 'RESET_JOBS'

// datasets
export const FETCH_DATASETS = 'FETCH_DATASETS'
export const FETCH_DATASETS_SUCCESS = 'FETCH_DATASETS_SUCCESS'
export const RESET_DATASETS = 'RESET_DATASETS'
export const FETCH_DATASET_VERSIONS = 'FETCH_DATASET_VERSIONS'
export const FETCH_DATASET_VERSIONS_SUCCESS = 'FETCH_DATASET_VERSIONS_SUCCESS'
export const RESET_DATASET_VERSIONS = 'RESET_DATASET_VERSIONS'

// lineage
export const FETCH_LINEAGE = 'FETCH_LINEAGE'
export const FETCH_LINEAGE_SUCCESS = 'FETCH_LINEAGE_SUCCESS'
export const RESET_LINEAGE = 'RESET_LINEAGE'

// search
export const FETCH_SEARCH = 'FETCH_SEARCH'
export const FETCH_SEARCH_SUCCESS = 'FETCH_SEARCH _SUCCESS'
