/**
 *Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 *You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 *The code base belongs to Data & AI Services - ADAI Practice
 *Please refer to the agreement/license for specific  governing permissions and
 *limitations under the License.
 *Code Author and Ownership:
 *
 *Prabhu Parthasarathy ,
 *Sr. Product Architect,
 *Email: prabhu.parthasarathy@kyndryl.com
 */
import { createTheme } from '@material-ui/core'

export const theme = createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          color: '#fff'
        }
      }
    }
  },
  typography: {
    fontFamily: 'IBM Plex Sans, Helvetica Neue, Arial, sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 600
    },
    h3: {
      fontSize: '1rem',
      fontWeight: 600
    },
    fontSize: 14
  },
  palette: {
    type: 'dark',
    primary: {
      main: '#4cdd84'
    },
    error: {
      main: '#ee7b7b'
    },
    background: {
      default: '#191f26'
    },
    secondary: {
      main: '#454f5b'
    }
  }
})

export const THEME_EXTRA = {
  typography: {
    subdued: '#abb1bd',
    disabled: '#8d9499'
  }
}

// 1px for bottom border
export const HEADER_HEIGHT = 96 + 1
export const DRAWER_WIDTH = 96
