/**
 *Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 *You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 *The code base belongs to Data & AI Services - ADAI Practice
 *Please refer to the agreement/license for specific  governing permissions and
 *limitations under the License.
 *Code Author and Ownership:
 *
 *Prabhu Parthasarathy ,
 *Sr. Product Architect,
 *Email: prabhu.parthasarathy@kyndryl.com
 */
import { API_URL } from '../../globals'
import { Namespaces } from '../../types/api'
import { genericFetchWrapper } from './index'

export const getNamespaces = async () => {
  const url = `${API_URL}/namespaces`
  return genericFetchWrapper<Namespaces>(url, { method: 'GET' }, 'fetchNamespaces')
}
