/**
 *Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 *You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 *The code base belongs to Data & AI Services - ADAI Practice
 *Please refer to the agreement/license for specific  governing permissions and
 *limitations under the License.
 *Code Author and Ownership:
 *
 *Prabhu Parthasarathy ,
 *Sr. Product Architect,
 *Email: prabhu.parthasarathy@kyndryl.com
 */
import moment from 'moment'

function addLeadingZero(number: number) {
  if (number.toString().length === 1) {
    return `0${number.toString()}`
  }
  return number
}

export function stopWatchDuration(durationMs: number) {
  const duration = moment.duration(durationMs, 'ms')
  if (duration.asMilliseconds() === 0) {
    return '0'
  }
  if (duration.asHours() > 24) {
    return `${duration.days()}d ${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`
  }
  if (duration.asMinutes() > 60) {
    return `${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`
  }
  if (duration.asSeconds() > 1) {
    return `${duration.minutes()}m ${addLeadingZero(duration.seconds())}s`
  } else {
    return `${duration.asMilliseconds()} ms`
  }
}
