exports = module.exports = require("../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/*\r\n *Copyright (C) 2022 Kyndryl Inc All Rights Reserved\r\n *You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.\r\n *The code base belongs to Data & AI Services - ADAI Practice\r\n *Please refer to the agreement/license for specific  governing permissions and\r\n *limitations under the License.\r\n *Code Author and Ownership:\r\n *\r\n *Prabhu Parthasarathy ,\r\n *Sr. Product Architect,\r\n *Email: prabhu.parthasarathy@kyndryl.com\r\n */\r\n/* COLORS */\r\n", ""]);

// Exports
exports.locals = {
	"neptune": "#319795",
	"telescopeBlack": "#2B2B33",
	"offWhite": "#E5E5E5",
	"deepRed": "#CC0E1D",
	"vibrantGreen": "#4CDD84",
	"jobNodeGrey": "#7d7d7d",
	"datasetNodeWhite": "#fff",
	"linkGrey": "#f3f3f3",
	"jobRunNew": "#3199FF",
	"jobRunFailed": "#ee7b7b",
	"jobRunCompleted": "#4CDD84",
	"jobRunAborted": "#7D7D7D",
	"jobRunRunning": "#FECC00"
};