/**
 *Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 *You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 *The code base belongs to Data & AI Services - ADAI Practice
 *Please refer to the agreement/license for specific  governing permissions and
 *limitations under the License.
 *Code Author and Ownership:
 *
 *Prabhu Parthasarathy ,
 *Sr. Product Architect,
 *Email: prabhu.parthasarathy@kyndryl.com
 */
import { ArrowBackIosRounded } from '@material-ui/icons'
import { Box, Chip, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { DatasetVersion } from '../../types/api'
import { Theme as ITheme } from '@material-ui/core/styles/createTheme'
import { WithStyles as IWithStyles } from '@material-ui/core/styles/withStyles'
import { alpha, createStyles, withStyles } from '@material-ui/core/styles'
import { formatUpdatedAt } from '../../helpers'
import DatasetInfo from './DatasetInfo'
import IconButton from '@material-ui/core/IconButton'
import MqText from '../core/text/MqText'
import React, { FunctionComponent, SetStateAction } from 'react'
import transitions from '@material-ui/core/styles/transitions'
import { Tabs } from 'material-ui'

const styles = (theme: ITheme) => {
  return createStyles({
    tableRow: {
      cursor: 'pointer',
      transition: transitions.create(['background-color']),
      '&:hover': {
        backgroundColor: theme.palette.common.white,
      }
    },
    tabs: {
      color: theme.palette.common.black,
      border: 'none',
      fontSize: '12px',
    }
  })
}

const DATASET_VERSIONS_COLUMNS = ['Version', 'Created At', 'Field Count']

interface DatasetVersionsProps {
  versions: DatasetVersion[]
}

const DatasetVersions: FunctionComponent<
  DatasetVersionsProps & IWithStyles<typeof styles>
> = props => {
  const { versions, classes } = props

  const [infoView, setInfoView] = React.useState<DatasetVersion | null>(null)
  const handleClick = (newValue: SetStateAction<DatasetVersion | null>) => {
    setInfoView(newValue)
  }

  if (versions.length === 0) {
    return null
  }
  if (infoView) {
    return (
      <>
        <Box display={'flex'} alignItems={'center'} width={'100%'} justifyContent={'space-between'} >
        <Chip label={infoView.version} />
        <IconButton onClick={() => handleClick(null)}>
          <ArrowBackIosRounded fontSize={'small'} />
        </IconButton>
        </Box>
        
        <DatasetInfo datasetFields={infoView.fields} facets={infoView.facets} />
      </>
    )
  }
  return (
    <Box>
      <Table size='small' style={{boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.2)"}}>
      <TableHead>
        <TableRow>
          {DATASET_VERSIONS_COLUMNS.map(column => {
            return (
              <TableCell key={column} align='left' className={classes.tabs} >
                <MqText subheading inline>
                  {column}
                </MqText>
              </TableCell>
            )
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {versions.map((version,index) => {
          return (
            <TableRow style ={ index % 2? { background : "white" }:{ background : "#F5F5F5" }}
              className={classes.tableRow}
              key={version.createdAt}
              onClick={() => handleClick(version)}
            >
              <TableCell align='left' className={classes.tabs}>{version.version}</TableCell>
              <TableCell align='left' className={classes.tabs}>{formatUpdatedAt(version.createdAt)}</TableCell>
              <TableCell align='left' className={classes.tabs}>{version.fields.length}</TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
    </Box>
    
  )
}

export default withStyles(styles)(DatasetVersions)
