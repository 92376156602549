/**
 *Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 *You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 *The code base belongs to Data & AI Services - ADAI Practice
 *Please refer to the agreement/license for specific  governing permissions and
 *limitations under the License.
 *Code Author and Ownership:
 *
 *Prabhu Parthasarathy ,
 *Sr. Product Architect,
 *Email: prabhu.parthasarathy@kyndryl.com
 */
import { FETCH_RUNS, FETCH_RUNS_SUCCESS, RESET_RUNS } from '../actionCreators/actionTypes'
import { Run } from '../../types/api'
import { fetchRunsSuccess } from '../actionCreators'

export type IRunsState = { isLoading: boolean; result: Run[]; init: boolean }

export const initialState: IRunsState = { isLoading: false, result: [], init: false }

type IRunsAction = ReturnType<typeof fetchRunsSuccess>

export default (state = initialState, action: IRunsAction): IRunsState => {
  const { type, payload } = action
  switch (type) {
    case FETCH_RUNS:
      return { ...state, isLoading: true }
    case FETCH_RUNS_SUCCESS:
      return { ...state, isLoading: false, init: true, result: payload.runs }
    case RESET_RUNS:
      return initialState
    default:
      return state
  }
}
