/**
 *Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 *You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 *The code base belongs to Data & AI Services - ADAI Practice
 *Please refer to the agreement/license for specific  governing permissions and
 *limitations under the License.
 *Code Author and Ownership:
 *
 *Prabhu Parthasarathy ,
 *Sr. Product Architect,
 *Email: prabhu.parthasarathy@kyndryl.com
 */
export const DAGRE_CONFIG = {
  rankdir: 'LR',
  marginx: 140,
  marginy: 40,
  align: 'UL',
  ranker: 'network-simplex',
  edgesep: 60,
  ranksep: 140
}

export const NODE_SIZE = 0

export const INITIAL_TRANSFORM = {
  scaleX: 1,
  scaleY: 1,
  translateX: 0,
  translateY: 0,
  skewX: 0,
  skewY: 0
}
