/**
 *Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 *You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 *The code base belongs to Data & AI Services - ADAI Practice
 *Please refer to the agreement/license for specific  governing permissions and
 *limitations under the License.
 *Code Author and Ownership:
 *
 *Prabhu Parthasarathy ,
 *Sr. Product Architect,
 *Email: prabhu.parthasarathy@kyndryl.com
 */
import { Box, Container, CssBaseline, Tabs } from '@material-ui/core'
import { ConnectedRouter, routerMiddleware } from 'connected-react-router'
import { Helmet } from 'react-helmet'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createBrowserHistory } from 'history'
import { theme } from '../helpers/theme'
import BottomBar from './bottom-bar/BottomBar'
import Datasets from '../routes/datasets/Datasets'
import Header from './header/Header'
import Jobs from '../routes/jobs/Jobs'
import Lineage from './lineage/Lineage'
import React, { ReactElement } from 'react'
import Sidenav from './sidenav/Sidenav'
import Toast from './Toast'
import createRootReducer from '../store/reducers'
import createSagaMiddleware from 'redux-saga'
import rootSaga from '../store/sagas'
import { useLocation } from 'react-router-dom';
import { Button, CircularProgress, Tab } from '@material-ui/core'
import { ChangeEvent, FunctionComponent, SetStateAction, useEffect } from 'react'
import { RouteComponentProps, Link as RouterLink,} from 'react-router-dom'



const sagaMiddleware = createSagaMiddleware({
  onError: (error, _sagaStackIgnored) => {
    console.log('There was an error in the saga', error)
  }
})
const history = createBrowserHistory()
const historyMiddleware = routerMiddleware(history)


const store = createStore(
  createRootReducer(history),
  composeWithDevTools(applyMiddleware(sagaMiddleware, historyMiddleware))
)

sagaMiddleware.run(rootSaga)

const TITLE = 'Data Traceability'

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}


const App = (): ReactElement => {

  const [tab, setTab] = React.useState(0)
  const handleChange = (event: ChangeEvent, newValue: SetStateAction<number>) => {
    setTab(newValue)
  }
  
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <MuiThemeProvider theme={theme}>
          <Helmet>
            <title>{TITLE}</title>
          </Helmet>
          <CssBaseline />
          
            <Box ml={2} mr={4}> 
            {/* <Sidenav/>   */}
            <Tabs  value={tab} onChange={handleChange} textColor='secondary' indicatorColor='primary'>
              {/* <RouterLink to={'/'} style={{textDecoration: 'none'}} >
                <Tab label='Jobs' {...a11yProps(0)} disableRipple={true} style={{color: theme.palette.common.black,fontSize: '12px',textTransform: 'none', borderBottom: '2px solid #4CDD84',}}/>
              </RouterLink> */}
              <RouterLink to={'/'} style={{textDecoration: 'none'}} >
                <Tab label='Datasets' {...a11yProps(1)}  disableRipple={true} style={{color: theme.palette.common.black,fontSize: '12px',textTransform: 'none', borderBottom: '2px solid #4CDD84',marginLeft: '5px'}}/>
              </RouterLink> 
            </Tabs>
            {/* {tab === 0 ? <Jobs/>: <Datasets/> } */}
              <Switch>
                <Route path={'/'} exact>
                  <Datasets />
                </Route>
                <Route path={'/datasets'} exact>
                  <Datasets />
                </Route>
                <Route path={'/lineage/:nodeType/:namespace/:nodeName'}>
                  <Lineage />
                  <BottomBar />
                </Route>
              </Switch>
              <Toast />
            </Box>
          
        </MuiThemeProvider>
      </ConnectedRouter>
    </Provider>
  )
}

export default App
