/**
 *Copyright (C) 2022 Kyndryl Inc All Rights Reserved
 *You may not use, distribute and modify this code under the terms specified by Kyndryl License/ Agreement.
 *The code base belongs to Data & AI Services - ADAI Practice
 *Please refer to the agreement/license for specific  governing permissions and
 *limitations under the License.
 *Code Author and Ownership:
 *
 *Prabhu Parthasarathy ,
 *Sr. Product Architect,
 *Email: prabhu.parthasarathy@kyndryl.com
 */
import * as Redux from 'redux'
import { Container, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { Dataset } from '../../types/api'
import { IState } from '../../store/reducers'
import { MqScreenLoad } from '../../components/core/screen-load/MqScreenLoad'
import { Nullable } from '../../types/util/Nullable'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { encodeNode } from '../../helpers/nodes'
import { fetchDatasets, resetDatasets } from '../../store/actionCreators'
import { formatUpdatedAt } from '../../helpers'
import Box from '@material-ui/core/Box'
import MqEmpty from '../../components/core/empty/MqEmpty'
import MqText from '../../components/core/text/MqText'
import React from 'react'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import Pagination from '@material-ui/lab/Pagination';
import { Theme } from '@material-ui/core'

const styles  = (theme: Theme) =>
createStyles({
  root: {
    '& .Mui-selected': {
      backgroundColor: '#29707a',
      color: '#FFFFFF',
    },
    '& .MuiPaginationItem-root': {
      color: '#3D3C3C',
      backgroundColor: '#f2f1ee',
      borderRadius: "0px",
      '&:hover' : {
        // backgroundColor: "rgba(0, 0, 0, 0.10)"
    },    
  }
}})

type PageState = {
  value: number;
};

interface StateProps {
  datasets: Dataset[]
  isDatasetsLoading: boolean
  isDatasetsInit: boolean
  selectedNamespace: Nullable<string>
  totalCount: number
}

interface DispatchProps {
  fetchDatasets: typeof fetchDatasets
  resetDatasets: typeof resetDatasets
}

type DatasetsProps = WithStyles<typeof styles> & StateProps & DispatchProps

const DATASET_COLUMNS = ['Name', 'Namespace', 'Source', 'Updated At']

class Datasets extends React.Component<DatasetsProps, PageState> {
  
  state: PageState = {
    value: 1,
  };

  private itemCountPerPage: number = 20 
  
  componentDidMount() {
    if (this.props.selectedNamespace) {
      this.props.fetchDatasets(this.props.selectedNamespace, this.itemCountPerPage, 0);
    }
  }

  componentDidUpdate(prevProps: Readonly<DatasetsProps>) {
    if (
      prevProps.selectedNamespace !== this.props.selectedNamespace &&
      this.props.selectedNamespace
    ) {
      this.props.fetchDatasets(this.props.selectedNamespace, this.itemCountPerPage, 0);
    }
  }

  componentWillUnmount() {
    this.props.resetDatasets()
  }

  handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (this.props.selectedNamespace) {
      const offset: number = value > 1 ? (value - 1) * this.itemCountPerPage : 0;
      this.props.fetchDatasets(this.props.selectedNamespace, this.itemCountPerPage, offset);
      this.setState({ value: value })
    }
  }

  render() {
    const { datasets, isDatasetsLoading, isDatasetsInit, totalCount, classes } = this.props
    const { value } = this.state;
    const total = totalCount !== 0 ? Math.floor(totalCount / this.itemCountPerPage) : 1;
    const pageSize = Math.floor(totalCount % this.itemCountPerPage) !== 0 ? (total + 1) : total;
    
    return (
      <Container maxWidth={'lg'} disableGutters>
        <MqScreenLoad loading={isDatasetsLoading || !isDatasetsInit}>
          <>
            {datasets.length === 0 ? (
              <Box p={2}>
                <MqEmpty title={'No datasets found'}>
                  <MqText subdued>
                    Try changing namespaces or consulting our documentation to add datasets.
                  </MqText>
                </MqEmpty>
              </Box>
            ) : (
              <>
                <Box p={2}>
                  <MqText heading>Datasets</MqText>
                </Box>
                <Table size='small' style={{boxShadow: "0 2px 6px 0 rgba(0, 0, 0, 0.2)"}}>
                  <TableHead >
                    <TableRow>
                      {DATASET_COLUMNS.map(field => {
                        return (
                          <TableCell key={field} align='left' style={{border: "none",}}>
                            <MqText subheading>{field}</MqText>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody >
                    {datasets.map((dataset,index) => {
                      return (
                        <TableRow key={dataset.name} style ={ index % 2? { background : "white" }:{ background : "#F5F5F5" }}>
                          <TableCell align='left' style={{border: "none",overflowWrap: 'break-word', }}>
                            <MqText
                              link
                              linkTo={`/lineage/${encodeNode(
                                'DATASET',
                                dataset.namespace,
                                dataset.name
                              )}`}
                            >
                              {dataset.name}
                            </MqText>
                          </TableCell>
                          <TableCell align='left' style={{border: "none",}}>
                            <MqText>{dataset.namespace}</MqText>
                          </TableCell>
                          <TableCell align='left' style={{border: "none",}}>
                            <MqText>{dataset.sourceName}</MqText>
                          </TableCell>
                          <TableCell align='left' style={{border: "none",}}>
                            <MqText>{formatUpdatedAt(dataset.updatedAt)}</MqText>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
                <Box p={2} style={{ width: 'fit-content', margin: 'auto' }}>
                  <Pagination count={pageSize} page={value} onChange={this.handleChange} className={classes.root} />
                </Box>
              </>
            )}
          </>
        </MqScreenLoad>
      </Container>
    )
  }
}

const mapStateToProps = (state: IState) => ({
  datasets: state.datasets.result.datasets,
  isDatasetsLoading: state.datasets.isLoading,
  isDatasetsInit: state.datasets.init,
  selectedNamespace: state.namespaces.selectedNamespace,
  totalCount: state.datasets.result.totalCount,
})

const mapDispatchToProps = (dispatch: Redux.Dispatch) =>
  bindActionCreators(
    {
      fetchDatasets: fetchDatasets,
      resetDatasets: resetDatasets
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Datasets))
